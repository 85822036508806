export default [
  {
    path: "groups",
    name: "groups",
    component: () => import("@/components/PassThrough.vue"),
    children: [
      {
        path: "list",
        name: "groups.view",
        component: () => import("@/views/group/ViewGroups.vue"),
      },
      {
        path: "create",
        name: "group.create",
        component: () => import("@/views/group/CreateGroup.vue"),
      },
      {
        path: ":group/edit",
        name: "group.edit",
        component: () => import("@/views/group/EditGroup.vue"),
      },
    ],
  },
];
