<template>
  <div class="side-bar d-flex flex-column flex-shrink-0 p-3">
    <a href="/" class="mb-3 mb-md-0 me-md-auto w-100 text-center fs-4">
      {{ $t("Bazar") }}
    </a>
    <ul class="nav nav-pills flex-column mb-auto my-3 p-0">
      <li v-for="(el, key) in sidebar" :key="key" class="my-1">
        <router-link
          :to="{ name: el.route }"
          class="nav-link"
          :class="$route.name === el.route ? 'active' : ''"
          aria-current="page"
        >
          <font-awesome-icon :icon="el.icon" class="mx-2" />
          {{ $t(el.name) }}
        </router-link>
      </li>
    </ul>
    <hr />
    <div class="col-auto" v-if="Auth().isAuth">
      <button class="btn-theme" type="button" @click="logout">
        {{ Auth().name }}
        <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { ElementsStore } from "@/stores/elements";
import { Auth } from "@/stores/auth/auth";
import router from "@/router";

const sidebar = ref(ElementsStore().sidebar);

function logout() {
  Auth().logout();
  router.push({ name: "auth.login" });
}
</script>
