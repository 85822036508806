import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import i18n from "./i18n";
import App from "./App.vue";
import router from "./router";
import VueAxios from "vue-axios";
import axios from "./axios";
import Toast from "vue-toastification";
import VueAwesomePaginate from "vue-awesome-paginate";
import { vfmPlugin } from "vue-final-modal";

import Draggable from "vuedraggable";
import "bootstrap";

import "vue-awesome-paginate/dist/style.css";

import "vue-toastification/dist/index.css";
import "@/assets/main.scss";
import "@/assets/headless.scss";
import "@/assets/colors.scss";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
library.add(fas);
library.add(fab);
library.add(far);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(pinia);
app.use(vfmPlugin);
app.use(Toast, {});
app.use(VueAwesomePaginate);
app.use(VueAxios, axios);
import VueUploadComponent from "vue-upload-component";

app.component("v-select", vSelect);
app.component("file-upload", VueUploadComponent);
app.component("draggable-component", Draggable);
app.component("font-awesome-icon", FontAwesomeIcon);
const domain =
  window.location.hostname === "localhost"
    ? `http://${window.location.hostname}:8000`
    : window.location.origin;
const myPlugin = {
  install: (app: any, options: any) => {
    app.config.globalProperties.$bPath = (path: any) => {
      return path ? `${domain}/${path}` : `${domain}/uploads/image/noImage.jpg`;
    };
  },
};
app.use(myPlugin);
app.mount("#app");
