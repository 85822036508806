<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="container-fluid">
          <div class="row justify-content-end">
            <div class="col-auto position-relative">
              <Listbox v-model="selectedTheme">
                <ListboxButton class="btn-theme">
                  <font-awesome-icon
                    class="pe-2"
                    :icon="themes.find((e) => e.value === selectedTheme).icon"
                  />
                  {{ $t(selectedTheme) }}
                </ListboxButton>
                <ListboxOptions class="list-box-options-theme">
                  <ListboxOption
                    v-for="theme in themes"
                    :key="theme.id"
                    :value="theme.value"
                    @click="changeTheme(theme.value)"
                    :class="`hover-bg-color-${selectedColor} ${
                      theme.value === selectedTheme ? 'active' : null
                    }`"
                  >
                    <font-awesome-icon :icon="theme.icon" />
                    {{ $t(theme.name) }}
                  </ListboxOption>
                </ListboxOptions>
              </Listbox>
            </div>
            <div class="col-auto position-relative">
              <Listbox v-model="selectedColor">
                <ListboxButton class="btn-theme">
                  <font-awesome-icon icon="fa-solid fa-palette" />
                </ListboxButton>
                <ListboxOptions class="list-box-options-theme">
                  <ListboxOption
                    v-for="color in themes.find(
                      (e) => e.value === selectedTheme
                    ).colors"
                    :key="color"
                    :value="color"
                    @click="changeColor(color)"
                    :class="`bg-type-${selectedTheme}-color-${color}`"
                  >
                    {{ $t("Chose Theme Color") }} ({{ color }})
                  </ListboxOption>
                </ListboxOptions>
              </Listbox>
            </div>

            <div class="col-auto" v-if="Auth().isAuth">
              <button class="btn-theme" type="button" @click="logout">
                {{ Auth().name }}
                <font-awesome-icon
                  icon="fa-solid fa-arrow-right-from-bracket"
                />
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 mt-2 p-0 d-lg-none">
          <side-bar-component />
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";

import SideBarComponent from "@/components/SideBarComponent.vue";
import { Auth } from "@/stores/auth/auth";
import { StyleStore } from "@/stores/style";
import router from "@/router";

const themes = ref(StyleStore().themes);
const selectedTheme = ref(StyleStore().theme);
const selectedColor = ref(StyleStore().color);

function changeTheme(val) {
  StyleStore().theme = val;
  StyleStore().color = 1;
}

function changeColor(val) {
  StyleStore().color = val;
}

function logout() {
  Auth().logout();
  router.push({ name: "auth.login" });
}
</script>
