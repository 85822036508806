import axios from "axios";
// import { SettingStore } from "@/stores/setting";
// import { ModelStore } from "@/stores/model";
import { useToast } from "vue-toastification";
import { Auth } from "@/stores/auth/auth";
import router from "@/router";

const domain =
  window.location.hostname === "localhost"
    ? `http://${window.location.hostname}:8000`
    : window.location.origin;
const axiosIns = axios.create({
  baseURL: `${domain}/api/acp/`,
});
axiosIns.defaults.withCredentials = true;
axiosIns.interceptors.request.use(
  (config) => {
    config.headers = {
      Accept: `application/json; charset=utf-8; boundary=${Math.random().toString()}`,
      Authorization: Auth().isAuth ? `Bearer ${Auth().token}` : "",
    };
    // ModelStore().setResponseType(config.method);
    // ModelStore().OpenLoading();
    return config;
  },
  (error) => {
    // ModelStore().CloseLoading();
    return Promise.reject(error);
  }
);

axiosIns.interceptors.response.use(
  (response) => {
    // ModelStore().CloseLoading();
    if (
      ["post", "delete", "patch", "put"].includes(
        <string>response.config.method
      )
    ) {
      if (response.data.message) {
        useToast().success(response.data.message);
      }
    }
    return response;
  },
  (error) => {
    // ModelStore().CloseLoading();

    const error_code = error?.response?.status;
    if (error.message === "Network Error") {
      useToast().error("check your internet connection");
    } else if (error_code === 401) {
      useToast().error("you are not authorize!");
      Auth().logout();
      router.push({ name: "auth.login" });
    } else if (error_code === 402) {
      useToast().error(error?.response?.data?.message ?? error.message);
    } else if (error_code === 403) {
      useToast().error(error?.response?.data?.message ?? error.message);
    } else if (error_code === 404) {
      useToast().error(error?.response?.data?.message ?? error.message);
    } else if (error_code === 422) {
      useToast().error(error?.response?.data?.message ?? error.message);
    } else if (error_code === 500) {
      useToast().error(error?.response?.data?.message ?? error.message);
    }
    return Promise.reject(error);
  }
);
export default axiosIns;
