export default [
  {
    path: "tags",
    name: "tags",
    component: () => import("@/components/PassThrough.vue"),
    children: [
      {
        path: "list",
        name: "tags.view",
        component: () => import("@/views/tag/ViewTags.vue"),
      },
      {
        path: "create",
        name: "tag.create",
        component: () => import("@/views/tag/CreateTag.vue"),
      },
      {
        path: "order",
        name: "tag.order",
        component: () => import("@/views/tag/OrderTag.vue"),
      },
      {
        path: ":tag/edit",
        name: "tag.edit",
        component: () => import("@/views/tag/EditTag.vue"),
      },
      {
        path: ":tag/show",
        name: "tag.show",
        component: () => import("@/views/tag/ShowTag.vue"),
      },
    ],
  },
];
