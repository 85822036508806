import {defineStore} from 'pinia'

export const StyleStore = defineStore('style', {
    state: () => ({
        themes: [
            {name: 'Light', value: 'light',icon:'fa-solid fa-sun',colors:2},
            {name: 'Dark', value: 'dark',icon:'fa-solid fa-moon',colors:2},
            {name: 'Colors', value: 'color',icon:'fa-solid fa-paint-roller',colors:10},
            {name: 'System', value: 'dark',icon:'fa-solid fa-desktop',colors:2}
        ],
        theme: 'color',
        color: 1,
    }),
    persist: true,
    getters: {},
});
