export default [
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/components/PassThrough.vue"),
    children: [
      {
        path: "login",
        name: "auth.login",
        component: () => import("@/views/auth/AuthLogin.vue"),
        meta: { layout: "full" },
      },
    ],
  },
];
