<template>
  <component :is="layout"></component>
</template>

<script setup>
import { computed } from "vue";
import VerticalLayout from "@/layouts/VerticalLayout.vue";
import FullLayout from "@/layouts/FullLayout.vue";
import router from "@/router";

const layout = computed(() => {
  if (
    router.currentRoute.value?.meta?.layout === "full" ||
    router.currentRoute.value.name === undefined
  )
    return FullLayout;
  return VerticalLayout;
});
</script>
