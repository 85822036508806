import { createI18n } from "vue-i18n";

const i18n = createI18n({
  locale: "ar",
  fallbackLocale: "ar",
  silentTranslationWarn: true,
  messages: {
    en: {
      hello: "hello",
    },
    ar: {
      full_name: "الاسم",
      name: "الاسم",
      status: "الحالة",
      active: "فعال",
      deactivate: "متوقف",
      tag_type: "نوع القسم",
      Tags: "أنواع",
      options: "خيارات",
      category: "قسم",
      company: "شركة ",
      companyDontHaveDistributorIn: " شركة لاتحوي على موزع في مدينة ",
      Dark: "داكن",
      Light: "فاتح",
      Colors: "الألوان",
      System: "النظام",
      Dashboard: "لوحة التحكم",
      Users: "المستخدمون",
      Products: "المنتجات",
      Orders: "الطلبات",
      Groups: "المجموعات",
      Areas: "المناطق",
      Sliders: "الشرائح",
      Admin: "المدير",
      Supplier: "المورد",
      Customer: "العميل",
      area: "المنطقة",
      phone: "الهاتف",
      role: "الدور",
      no_data: "لا يوجد بيانات",
      CreatedAt: "تاريخ الإنشاء",
      UpdatedAt: "تاريخ التحديث",
      user_name: "اسم المستخدم",
      search: "بحث",
      Search: "بحث",
      filter: "تصفية",
      clear: "مسح",
      blocked: "محظور",
      ID: "المعرف",
      UUID: "المعرف الشامل",
      Store: "إنشاء",
      Area: "المنطقة",
      Setting: "الأعدادات",
      Phone: "الهاتف",
      Role: "الدور",
      Password: "كلمة المرور",
      Address: "العنوان",
      Close: "إغلاق",
      Page: "الصفحة",
      of: "من",
      Update: "تحديث",
      description: "الوصف",
      Description: "الوصف",
      Company: "الشركة",
      supplier_count: "عدد الموردين",
      customer_count: "عدد العملاء",
      companies_count: "عدد الشركات",
      companies_in_home_page: "الشركات في الصفحة الرئيسية",
      categories_in_home_page: "الفئات في الصفحة الرئيسية",
      sliders_in_home_page: "الشرائح في الصفحة الرئيسية",
      add_new_elements_to_home_page: "إضافة عناصر جديدة إلى الصفحة الرئيسية",
      area_supplier: "منطقة المورد",
      image: "صورة",
      products_in_home_page: "المنتجات في الصفحة الرئيسية",
      remove_all_companies: "إزالة كل الشركات",
      remove_all_categories: "إزالة كل الفئات",
      categories: "الفئات",
      remove_all_products: "إزالة كل المنتجات",
      products: "المنتجات",
      remove_all_sliders: "إزالة كل الشرائح",
      remove_all_suppliers: "إزالة كل الموردين",
      companies: "الشركات",
      parent: "الأصل-الأب",
      sliders: "الشرائح",
      products_count: "عدد المنتجات",
      tag: "الوسم",
      type: "النوع",
      Type: "النوع",
      Category: "الفئة",
      types: "أنواع",
      Bazar: "بازار",
      Image: "الصورة",
      Images: "الصور",
      quantity: "العدد",
      amount: "الكمية",
      categories_count: "عدد الفئات",
      dark: "الداكن",
      light: "الخفيف",
      slag_name: "اسم الخرسانة",
      Product: "المنتج",
      elements_count: "عدد العناصر",
      "Chose Theme Color": "اختر لون الموضوع",
      "+ Add to home page in this Area":
        "+ إضافة إلى الصفحة الرئيسية في هذه المنطقة",
      "Slag Name": "اسم اللطيف",
      "Create new Slider": "إنشاء الشريحة جديد",
      "Slider Name": "اسم الشريحة",
      "Update slider": "تحديث الشريحة",
      "Create new slider": "إنشاء شريحة جديد",
      "Update product": "تحديث المنتج",
      "Create new product": "إنشاء منتج جديد",
      "Update group": "تحديث المجموعة",
      "Create new group": "إنشاء مجموعة جديدة",
      "Create new Group": "إنشاء مجموعة جديدة",
      "reorder companies": "إعادة ترتيب الشركات",
      "Product Name": "اسم المنتج",
      "Product Description": "وصف المنتج",
      "Product Quantity": "عدد المنتج",
      "Product Amount": "كمية المنتج",
      "Product Category": "فئة المنتج",
      "Product Company": "شركة المنتج",
      "Create New Group": "إنشاء مجموعة جديدة",
      "Select Groups": "تحديد المجموعات",
      "Parent Type": "النوع الأب",
      "Create Tag": "إنشاء وسم",
      "Group Name": "اسم المجموعة",
      "Group slag Name": "اسم الشعبية للمجموعة",
      "Tag Name": "اسم الوسم",
      "Tag Type": "نوع الوسم",
      "save companies order": "حفظ ترتيب الشركات",
      "save Category order": "حفظ ترتيب التصنيفات",
      "Save Type Order": "حفظ ترتيب الانواع",
      "Create new tag": "إنشاء وسم جديد",
      "Order Type": "ترتيب النوع",
      "Save Company Order": "حفظ ترتيب الشركة",
      "reorder categories": "إعادة ترتيب التصنيفات",
      "save categories order": "حفظ ترتيب التصنيفات",
      "reorder products": "إعادة ترتيب المنتجات",
      "save products order": "حفظ ترتيب المنتجات",
      "reorder sliders": "إعادة ترتيب المعرض",
      "+ Add Companies to home page in this Area":
        "إضافة الشركات إلى الصفحة الرئيسية في هذه المنطقة",
      "+ Add Categories to home page in this Area":
        "إضافة التصنيفات إلى الصفحة الرئيسية في هذه المنطقة",
      "+ Add Products to home page in this Area":
        "إضافة الشرائح إلى الصفحة الرئيسية في هذه المنطقة",
      "+ Add Sliders to home page in this Area":
        "إضافة المعرض إلى الصفحة الرئيسية في هذه المنطقة",

      "save sliders order": "حفظ ترتيب المعرض",
      "change Supplier": "تغيير المورد",
      "Select Supplier for this company": "اختيار المورد لهذه الشركة",
      "Change Supplier": "تغيير المورد",
      "Area Name": "اسم المنطقة",
      "Update area": "تحديث المنطقة",
      "Create new area": "إنشاء منطقة جديدة",
      "Create new Area": "إنشاء منطقة جديدة",
      "Update user": "تحديث المستخدم",
      "Change Password": "تغيير كلمة المرور",
      "Create new user": "إنشاء مستخدم جديد",
      "Create new User": "إنشاء مستخدم جديد",
      "User Name": "اسم المستخدم",
      "Looks good!": "نظيفة جدا!",
      "First Name": "الاسم الأول",
      "Last Name": "الاسم الأخير",
      "Password Confirmation": "تأكيد كلمة المرور",
      "change Password": "تغيير كلمة المرور",
      "Choose a strong password and don't reuse it for other accounts":
        "اختر كلمة مرور قوية ولا تعيد استخدامها لحسابات أخرى",
      "from all his devices": "من جميع أجهزته",
      "Changing password will sign out user":
        "تغيير كلمة المرور سوف يؤدي إلى تسجيل الخروج من المستخدم",
      "min app version": "أدنى إصدار تطبيق",
      "min android app version": "أدنى إصدار تطبيق أندرويد",
      "min apple app version": "أدنى إصدار تطبيق أبل",
      "last android app version": "اخر إصدار تطبيق أندرويد",
      "last apple app version": "اخر إصدار تطبيق أبل",
      usd_to_tl: "الدولار الأمريكي إلى الليرة التركية",
      "Developing Mode": "وضع التطوير",
      app_store_url: "رابط متجر التطبيقات",
      "google play url": "رابط جوجل بلاي",
      "app gallery url": "رابط معرض التطبيقات",
      "app external url": "رابط خارجي للتطبيق",
      "whatsapp url": "رابط الواتساب",
      "telegram url": "رابط التليجرام",
      "facebook url": "رابط الفيسبوك",
      "instagram url": "رابط انستجرام",
      "youtube url": "رابط اليوتيوب",
      "suggestion url": "رابط الاقتراحات",
      "customer explanation url": "رابط شرح العملاء",
      "supplier explanation url": "رابط شرح الموردين",
      Order: "الطلبات",
      "complaints url": "رابط الشكاوى",
      saveSetting: "حفظ الإعدادات",
      "Mobile App Setting": "إعدادات التطبيق",
      contact: "تواصل",
      disabled: "معطل",
      enabled: "مفعل",
      "Welcome to Bazar.": "مرحباً بك في بازار.",
      "Enter your user_name here": "أدخل اسم المستخدم الخاص بك هنا",
      "Enter your Password Here": "أدخل كلمة المرور الخاصة بك هنا",
      Login: "تسجيل الدخول",
      color: "اللون",
    },
  },
});

export default i18n;
