import { defineStore } from "pinia";
import axios from "@/axios";
import router from "@/router";

export const Auth = defineStore("auth", {
  state: () => ({
    auth: {},
    token: null,
  }),
  persist: true,
  getters: {
    name: (state: any) => state?.auth?.name,
    user_name: (state: any) => state?.auth?.user_name,
    isAuth: (state: any) => !!state?.token || false,
  },
  actions: {
    login(payload: any, errors: any) {
      axios
        .post("login", payload)
        .then((response: any) => {
          this.token = response.data.token;
          this.auth = response.data.auth;
          router.push({ name: "home" }).then();
        })
        .catch((error: any) => {
          errors.value = error?.response?.data?.errors || {};
        });
    },
    logout() {
      this.token = null;
      this.auth = {};
    },
  },
});
