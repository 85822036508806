export default [
  {
    path: "users",
    name: "users",
    component: () => import("@/components/PassThrough.vue"),
    children: [
      {
        path: "list",
        name: "users.view",
        component: () => import("@/views/user/ViewUsers.vue"),
      },
      {
        path: "create",
        name: "user.create",
        component: () => import("@/views/user/CreateUser.vue"),
      },
      {
        path: ":user/edit",
        name: "user.edit",
        component: () => import("@/views/user/EditUser.vue"),
      },
      {
        path: ":user/show",
        name: "user.show",
        component: () => import("@/views/user/ShowUser.vue"),
      },
    ],
  },
];
