export default [
  {
    path: "products",
    name: "products",
    component: () => import("@/components/PassThrough.vue"),
    children: [
      {
        path: "list",
        name: "products.view",
        component: () => import("@/views/product/ViewProducts.vue"),
      },
      {
        path: "create",
        name: "product.create",
        component: () => import("@/views/product/CreateProduct.vue"),
      },
      {
        path: ":product/edit",
        name: "product.edit",
        component: () => import("@/views/product/EditProduct.vue"),
      },
      {
        path: ":product/show",
        name: "product.show",
        component: () => import("@/views/product/ShowProduct.vue"),
      },
    ],
  },
];
