export default [
  {
    path: "areas",
    name: "areas",
    component: () => import("@/components/PassThrough.vue"),
    children: [
      {
        path: "list",
        name: "areas.view",
        component: () => import("@/views/area/ViewAreas.vue"),
      },
      {
        path: "create",
        name: "area.create",
        component: () => import("@/views/area/CreateArea.vue"),
      },
      {
        path: ":area/edit",
        name: "area.edit",
        component: () => import("@/views/area/EditArea.vue"),
      },
      {
        path: ":area/show",
        name: "area.show",
        component: () => import("@/views/area/ShowArea.vue"),
      },
    ],
  },
];
