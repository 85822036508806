export default [
  {
    path: "sliders",
    name: "sliders",
    component: () => import("@/components/PassThrough.vue"),
    children: [
      {
        path: "list",
        name: "sliders.view",
        component: () => import("@/views/slider/ViewSliders.vue"),
      },
      {
        path: "create",
        name: "slider.create",
        component: () => import("@/views/slider/CreateSlider.vue"),
      },
      {
        path: ":slider/edit",
        name: "slider.edit",
        component: () => import("@/views/slider/EditSlider.vue"),
      },
    ],
  },
];
