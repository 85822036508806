import { createRouter, createWebHistory } from "vue-router";

import User from "@/router/user";
import Area from "@/router/area";
import Tag from "@/router/tag";
import AuthRoute from "@/router/auth";
import Product from "@/router/product";
import Group from "@/router/group";
import Slider from "@/router/slider";
import Order from "@/router/order";
import Setting from "@/router/setting";
import { Auth } from "@/stores/auth/auth";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/HomeView.vue"),
      meta: {
        auth_required: true,
      },
    },
    {
      path: "/acp",
      name: "acp",
      component: () => import("@/components/PassThrough.vue"),
      meta: {
        auth_required: true,
      },
      children: [
        ...User,
        ...Area,
        ...Tag,
        ...Product,
        ...Group,
        ...Slider,
        ...Order,
        ...Setting,
      ],
    },
    ...AuthRoute,

    {
      path: "/policy",
      name: "policy",
      component: () => import("@/components/PassThrough.vue"),
      children: [
        {
          path: "",
          name: "policy.page",
          component: () => import("@/views/PolicyPage.vue"),
          meta: { layout: "full" },
        },
      ],
    },
    // ...Error,

    // {
    //   path: "/:pathMatch(.*)*",
    //   redirect: "/error404",
    // },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to?.meta?.auth_required && !Auth().isAuth) {
    next({ name: "auth.login" });
  }
  next();
});
// router.afterEach(() => {
//   // ModelStore().CloseLoading();
// });

export default router;
