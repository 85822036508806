<template>
  <div
    class="theme-background height-fill-available container-fluid m-0 p-0"
    :class="`theme-type-${theme} theme-color-${color}`"
  >
    <div class="row m-0 p-0 height-fill-available">
      <div class="col-auto d-none m-0 p-0 d-lg-flex flex-nowrap">
        <side-bar-component />
      </div>
      <div class="col m-0 p-0">
        <navbar-component />
        <RouterView v-slot="{ Component, route }">
          <transition name="slide" mode="out-in">
            <div class="row m-0" :key="route.name">
              <component :is="Component"></component>
            </div>
          </transition>
        </RouterView>
      </div>
    </div>
  </div>
</template>

<script setup>
import NavbarComponent from "@/components/NavbarComponent.vue";
import SideBarComponent from "@/components/SideBarComponent.vue";
import { computed, ref } from "vue";
import { StyleStore } from "@/stores/style";

const theme = computed(() => {
  return StyleStore().theme;
});
const color = computed(() => {
  return StyleStore().color;
});
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
