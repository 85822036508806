import { defineStore } from "pinia";

export const ElementsStore = defineStore("elements", {
  state: () => ({
    sidebar: [
      { name: "Dashboard", route: "home", icon: "fa-solid fa-gauge" },
      { name: "Users", route: "users.view", icon: "fa-solid fa-user" },
      {
        name: "Areas",
        route: "areas.view",
        icon: "fa-solid fa-map-location-dot",
      },
      { name: "Tags", route: "tags.view", icon: "fa-solid fa-tags" },
      { name: "Products", route: "products.view", icon: "fa-solid fa-cube" },
      // { name: "Orders", route: "home", icon: "fa-solid fa-dolly" },
      { name: "Groups", route: "groups.view", icon: "fa-layer-group" },
      { name: "Sliders", route: "sliders.view", icon: "fa-solid fa-panorama" },
      { name: "Order", route: "order", icon: "fa-solid fa-cart-shopping" },
      { name: "Setting", route: "setting", icon: "fa-solid fa-gear" },
    ],
  }),
  getters: {},
});
